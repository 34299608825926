import "./bootstrap";
import "../css/app.css";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
// @ts-ignore
import { renderApp } from "@inertiaui/modal-react";

const appName = import.meta.env.VITE_APP_NAME || "Revista Exato";

createInertiaApp({
  title: title => `${title} - ${appName}`,
  resolve: name => {
    const page = resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob("./Pages/**/*.tsx"));
    page.then(module => {
      // @ts-ignore
      module.default.layout = module.default.layout; // || ShadcnAuthenticatedLayout;
    });
    return page;
  },
  setup({ el, App, props }) {
    const root = createRoot(el);

    // root.render(<App {...props} />);
    root.render(renderApp(App, props));
  },
  progress: {
    color: "#4B5563",
  },
});
